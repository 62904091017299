const DEFAULT_PAGE_SIZE = 20;
const HUNDRED_PAGE_SIZE = 100;
const DEFAULT_UPB_PAGE_SIZE = 50;
const DEFAULT_CATEGORY_VIEW_PAGE_SIZE = 100;
const NAVIGATION_SIDE_BAR_WIDTH = 70;
const UNCATEGORIZED = 'uncategorized';
const DEFAULT_THRESHOLD_VALUE_FOR_AI_TASKS = 0.4;
const CATEGORY_COLUMNS = [
  'l0_category',
  'l1_category',
  'l2_category',
  'l3_category',
  'l4_category',
];

const DEFAULT_PRODUCT_COLUMNS = [
  'name',
  'cnd_id',
  'brand_name',
  'sub_brand_name',
  'vendor',
  'vendor_sku',
  'pred_category',
  ...CATEGORY_COLUMNS,
];

const DEFAULT_VIEW = {
  name: 'Default View',
  columns: DEFAULT_PRODUCT_COLUMNS.map(column => ({ name: column, visible: true })),
};

const DEFAULT_GLOBAL_PRODUCT_COLUMNS = [
  'global_product_id',
  'name',
  'upc',
  'gtin',
  'manufacturer',
  ...CATEGORY_COLUMNS,
];
const GLOBAL_PRODUCT_COLUMNS = [
  ...DEFAULT_GLOBAL_PRODUCT_COLUMNS,
  'vendor',
  'vendor_sku',
  'brand',
  'categorized_by',
  'categorized_at',
  'size',
  'pack',
  'storage',
  'manufacturer_sku',
  'pack_size',
  'storage_temperature',
  'vendor_category',
  'vendor_subcategory',
  'tagged_interface',
];

const AUTOMATIC_CLASSIFIER_COLUMNS = [
  'vendor_sku',
  'vendor',
  'name',
  'l1_score',
  'l2_score',
  'l3_score',
  'l4_score',
  'l1_category',
  'l2_category',
  'l3_category',
  'l4_category',
  'brand',
  'manufacturer',
  'manufacturer_sku',
  'pack_size',
  'storage_temperature',
  'vendor_category',
  'gtin',
];

const SORTABLE_AUTOMATIC_CLASSIFIER_COLUMNS = [
  'l1_score',
  'l2_score',
  'l3_score',
  'l4_score',
  'name',
  'brand',
  'vendor',
  'manufacturer',
];

const CUT_DRY_NODE_URL = 'https://internal.cutanddry.com/admin/node/';

const CONFLICT_TYPES = {
  upcMismatch: 'UPC mismatch',
  gtinMismatch: 'GTIN mismatch',
  upcDuplicate: 'UPC duplicate',
  gtinDuplicate: 'GTIN duplicate',
};

const SORTABLE_GLOBAL_PRODUCT_COLUMNS = [
  'global_product_id',
  'name',
  'brand',
  'vendor',
  'manufacturer',
];
const SORT_DIRECTIONS = { ASC: 'asc', DESC: 'desc' };

const GOOGLE_REDIRECT_URL = `${window.location.origin}/login`;

const ADMIN_ROLE = 'admin';

const GLOBAL_PRODUCTS_TABLE_ID = 'table-1';
const TAXONOMY_TABLE_ID = 'table-2';
const GLOBAL_PRODUCTS_TAXONOMY_TABLE_ID = 'table-3';
const ALL_PRODUCTS_TABLE_ID = 'table-4';
const AUTO_PRODUCT_CLASSIFICATION_TABLE_ID = 'table-5';
const GLOBAL_PRODUCTS_PANE = 'pane-1';
const DASHBOARD_WIDGETS = 'dashboardWidgets5';

const CATEGORY_LEVELS = {
  L0_CATEGORY: 'l0_category',
  L1_CATEGORY: 'l1_category',
  L2_CATEGORY: 'l2_category',
  L3_CATEGORY: 'l3_category',
  L4_CATEGORY: 'l4_category',
};

const SIDE_BAR_DISABLED_PATHS = [
  '/global-products/classify',
  '/all-products',
  '/global-products/attribute',
  '/manage-attributes',
  '/ai-suggested-product-classifier',
];

const DATA_SOURCES_VIEW_TABS = {
  ATTRIBUTES: 'Attributes',
  SKU_MAPPINGS: 'Sku Mappings',
  PRODUCTS: 'Products',
  SUMMARY: 'Summary',
  SETTINGS: 'Settings',
  PROCESS_LOGS: 'Process Logs',
};

const PRODUCT_DETAIL_VIEW_TABS = {
  PDP_PREVIEW: 'PDP Preview',
  ATTRIBUTES: 'Attributes',
  ASSETS: 'Assets',
  RELATIONSHIPS: 'Relationships',
  CHANGE_LOG: 'Change Log',
};

const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY HH:mm:ss';
const DATE_FORMAT = 'MM/DD/YYYY';

const ATTRIBUTE_SECTIONS = {
  IMAGES: 'Images',
  CORE: 'Core',
  NUTRITION: 'Nutrition',
  HEALTH_AND_DIET: 'Health & Diet',
};

const CND_SYNC_STATUS = {
  YES: 'Yes',
  NO: 'No',
};

const THUMBNAIL_SOURCE_OPTIONS = [
  { value: null, label: 'All' },
  { value: -1, label: 'No Thumbnail' },
  { value: 'propagated', label: 'Propagated' },
  { value: 'l4_default', label: 'L4 Default Image' },
  { value: 'distributor', label: 'Distributor/Manufacturer' },
  { value: 'fsa_upload', label: 'FSA Upload' },
  { value: 'data_source', label: 'Data source' },
];
const ATTRIBUTE_SAMPLE_FILE =
  // eslint-disable-next-line max-len
  'https://fsa-categorization-tool.s3.us-east-2.amazonaws.com/sample_files/attribute_assign_for_taxonomies_sample_file.xlsx';

const SCHEMA_ATTRIBUTE_SAMPLE_FILE =
  // eslint-disable-next-line max-len
  'https://fsa-categorization-tool.s3.us-east-2.amazonaws.com/sample_files/attribute_assign_for_schema_sample_file.csv';

const ATTRIBUTE_UPDATE_SAMPLE_FILE =
  // eslint-disable-next-line max-len
  'https://fsa-categorization-tool.s3.us-east-2.amazonaws.com/sample_files/sample_attribute_update_file.csv';

const IMAGE_TAGGER_HELP_GUIDE_URL =
  'https://cnd-spec-sheets.s3.us-east-2.amazonaws.com/fsa/image_types.pdf';

const ALL_SELECT_OPTION = { label: 'ALL', value: '' };

const ADVANCED_MAPPING_FUNCTIONS = {
  CONCAT: {
    name: 'CONCAT',
    description: "CONCAT(PACK_SIZE,' ',PACK_UOM)",
    insertValue: "CONCAT(SOURCE_ATTRIBUTE,' ',SOURCE_ATTRIBUTE)",
    isQuickAdd: false,
  },
  REPLACE: {
    name: 'REPLACE',
    description: 'REPLACE(SOURCE_ATTRIBUTE,[find1,find2],[replace1,replace2])',
    insertValue: 'REPLACE(SOURCE_ATTRIBUTE,[],[])',
    isQuickAdd: true,
  },
  SUBSTRING: {
    name: 'SUBSTRING',
    description: 'SUBSTRING(shelf_life,0,3) converts "365 days" to "365"',
    insertValue: 'SUBSTRING(SOURCE_ATTRIBUTE,start,length)',
    isQuickAdd: false,
  },
  NUMERIC: {
    name: 'NUMERIC',
    description: 'NUMERIC(net_weight_value) converts "10 kg" to "10"',
    insertValue: 'NUMERIC(SOURCE_ATTRIBUTE)',
    isQuickAdd: true,
  },
  NON_NUMERIC: {
    name: 'NON_NUMERIC',
    description: 'NON_NUMERIC(net_weight_with_uom) converts "10 kg" to "kg"',
    insertValue: 'NON_NUMERIC(SOURCE_ATTRIBUTE)',
    isQuickAdd: true,
  },
  CASE: {
    name: 'CASE',
    description: 'CASE(net_weight_with_uom,UPPER) available options: UPPER, LOWER',
    insertValue: 'CASE(SOURCE_ATTRIBUTE,UPPER)',
    isQuickAdd: false,
  },
  SEARCH_RETURN: {
    name: 'SEARCH_RETURN',
    description:
      'SEARCH_RETURN(glutamate,[NoMSG,NoneMSG],MSG FREE) search for NoMSG and NoneMDG in glutamate If found return MSG FREE',
    insertValue: 'SEARCH_RETURN(SOURCE_ATTRIBUTE,[search1,search2],return)',
    isQuickAdd: true,
  },
  SPLIT: {
    name: 'SPLIT',
    description:
      'SPLIT(ingredients,|,[1:],-]) splits ingredients by | and returns rest of the string starting from 1st index concatenated with -',
    insertValue: 'SPLIT(SOURCE_ATTRIBUTE,delimiter,[start:end],concatenation)',
    isQuickAdd: true,
  },
  ADD_COLUMN: {
    name: 'ADD_COLUMN',
    description:
      'ADD_COLUMN(case_weight, LBR) if a values exists on the source column, LBR will be added to the mapped FSA attribute',
    insertValue: 'ADD_COLUMN(SOURCE_ATTRIBUTE, column value)',
    isQuickAdd: false,
  },
  MULTI_MAP: {
    name: 'MULTI_MAP',
    description:
      'MULTI_MAP(description) value of the description colum will be copied to the given source column',
    insertValue: 'MULTI_MAP(SOURCE_ATTRIBUTE)',
    isQuickAdd: false,
  },
  ADD_AFFIX: {
    name: 'ADD_AFFIX',
    description:
      'ADD_AFFIX(SOURCE_ATTRIBUTE, suffix, http://domain) "http://domain" will be added as a suffix to the selected source attribute if its available and mapped to the FSA attribute',
    insertValue: 'ADD_AFFIX(SOURCE_ATTRIBUTE,affix,string)',
    isQuickAdd: false,
  },
};

const CUT_DRY_STATIC_PRODUCT_URL = 'CUT_DRY_STATIC_PRODUCT_URL';
const IMAGE_URL = 'image_urls';

const FILE_DROP_PROD_S3_LOCATION = 's3://fsa-categorization-tool/data_imports/file_drops/prod/';
const FILE_DROP_DEV_S3_LOCATION = 's3://fsa-categorization-tool/data_imports/file_drops/dev/';

const CUT_DRY_CLIENT_ID = 1;

export {
  ADMIN_ROLE,
  ADVANCED_MAPPING_FUNCTIONS,
  ALL_PRODUCTS_TABLE_ID,
  ALL_SELECT_OPTION,
  ATTRIBUTE_SECTIONS,
  ATTRIBUTE_UPDATE_SAMPLE_FILE,
  AUTOMATIC_CLASSIFIER_COLUMNS,
  AUTO_PRODUCT_CLASSIFICATION_TABLE_ID,
  ATTRIBUTE_SAMPLE_FILE,
  CATEGORY_COLUMNS,
  CATEGORY_LEVELS,
  CND_SYNC_STATUS,
  CONFLICT_TYPES,
  CUT_DRY_NODE_URL,
  CUT_DRY_STATIC_PRODUCT_URL,
  CUT_DRY_CLIENT_ID,
  IMAGE_URL,
  DASHBOARD_WIDGETS,
  DATA_SOURCES_VIEW_TABS,
  DATE_FORMAT_WITH_TIME,
  DATE_FORMAT,
  DEFAULT_CATEGORY_VIEW_PAGE_SIZE,
  DEFAULT_GLOBAL_PRODUCT_COLUMNS,
  DEFAULT_PAGE_SIZE,
  HUNDRED_PAGE_SIZE,
  DEFAULT_PRODUCT_COLUMNS,
  DEFAULT_THRESHOLD_VALUE_FOR_AI_TASKS,
  DEFAULT_VIEW,
  DEFAULT_UPB_PAGE_SIZE,
  GLOBAL_PRODUCT_COLUMNS,
  GLOBAL_PRODUCTS_PANE,
  GLOBAL_PRODUCTS_TABLE_ID,
  GLOBAL_PRODUCTS_TAXONOMY_TABLE_ID,
  GOOGLE_REDIRECT_URL,
  IMAGE_TAGGER_HELP_GUIDE_URL,
  NAVIGATION_SIDE_BAR_WIDTH,
  PRODUCT_DETAIL_VIEW_TABS,
  SCHEMA_ATTRIBUTE_SAMPLE_FILE,
  SIDE_BAR_DISABLED_PATHS,
  SORT_DIRECTIONS,
  SORTABLE_AUTOMATIC_CLASSIFIER_COLUMNS,
  SORTABLE_GLOBAL_PRODUCT_COLUMNS,
  TAXONOMY_TABLE_ID,
  THUMBNAIL_SOURCE_OPTIONS,
  UNCATEGORIZED,
  FILE_DROP_PROD_S3_LOCATION,
  FILE_DROP_DEV_S3_LOCATION,
};
